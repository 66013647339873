<template>
  <div class="content">
    <div v-if="loading" class="container-fluid">
      <div class="row h-100">
        <div class="col-12 h-100">
          <div class="page-wrapper justify-content-center align-items-center h-100">
            <loading />
          </div>
        </div>
      </div>
    </div>
    <perfect-scrollbar @ps-scroll-y="onScroll" v-else class="w-100" ref="ps">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12" style="height:100px">
            <page-title />
          </div>
          <div class="col-12">
            <div class="page-wrapper">
              <page-wrapper-header
              :sorting="sorting"
                :titleItems="title"
                :listType="listingType"
                @sorting="changeSort"
                @changeListingType="changeListingType"
              />
              <div class="page-wrapper-body">
                <list
                  @click="clikModel"
                  :type="'model'"
                  :listingType="listingType"
                  :getList="list"
                />
                <div v-if="notMoreData && !loadingMore" class="row">
                  <div class="col-12">
                    <h3 class="justify-content-center d-flex w-100 mb-5 text-Keine">Keine weiteren Daten</h3>
                  </div>
                </div>
                <div v-if="loadingMore" class="row">
                  <div class="col-12">
                    <loading :size="'sm'" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <sidebar-filter :path="path"/>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import List from '@/components/common/List.vue'
import Loading from '../components/common/Loading.vue'
import PageTitle from '@/components/common/PageTitle.vue'
import SidebarFilter from '../components/common/SidebarFilter.vue'
import PageWrapperHeader from '../components/common/PageWrapperHeader.vue'
export default {
  components: { PageTitle, List, PageWrapperHeader, Loading, SidebarFilter },
  name: 'ModelList',
  data () {
    return {
      page: 1,
      list: [],
      path: '',
      query: {},
      title: [],
      sort: 'asc',
      paginator: {},
      loading: true,
      sorting: 'AZ',
      supplierId: null,
      notMoreData: false,
      loadingMore: false,
      listingType: 'box'
    }
  },
  computed: {
    ...mapGetters(['getUserOneRole', 'getListingType']),
    getList () {
      return this.list
    }
  },
  methods: {
    ...mapMutations(['SET_BREADCRUMB', 'SET_LISTING_TYPE']),
    onScroll ({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight + 2 >= scrollHeight) {
        this.moreData()
      }
    },
    moreData () {
      if (this.page === this.paginator.totalPages) {
        this.notMoreData = true
        return
      }
      if (!this.loadingMore) {
        this.page++
        this.getModelList(true)
      }
    },
    clikModel (par) {
      if (this.$route.name === 'sellerModels') {
        this.$router.push(
          `/seller/model/${par.modelId}?supplierId=${this.supplierId}`
        )
      }
      if (this.$route.name === 'buyerModels') {
        this.$router.push(
          `/buyer/model/${par.modelId}?supplierId=${this.supplierId}`
        )
      }
    },
    async getModelList (more = false) {
      try {
        if (more) {
          this.loadingMore = true
        }

        let filter = { modelStatus: 'active' }
        if (this.$route.query.modelStatus) {
          filter.modelStatus = this.$route.query.modelStatus
        }
        if (this.$route.query.modelId) {
          filter = { ...filter, modelId: this.$route.query.modelId }
        }
        if (this.$route.query.goodsGroup) {
          filter = { ...filter, goodsGroup: this.$route.query.goodsGroup }
        }
        if (this.$route.query.isExclusive) {
          filter = { ...filter, isExclusive: this.$route.query.isExclusive === 'true' }
        }
        if (this.$route.query.modelDiscontinued) {
          filter = { ...filter, modelDiscontinued: this.$route.query.modelDiscontinued === 'true' }
        }
        let query = {
          page: this.page,
          id: this.supplierId,
          sortType: this.sort
        }
        if (Object.keys(filter).length > 0) {
          query = { ...query, filter: JSON.stringify(filter) }
        }
        const response = await this.$api.getModelList(query)
        this.title = [
          {
            title: 'Modelle',
            count: response.data.data.statistics.totalModelCount
          },
          {
            title: 'Kataloge',
            count: response.data.data.statistics.totalCatalogueCount
          }
        ]
        this.paginator = response.data.data.paginator
        const items = response.data.data.items
        if (!more) {
          this.list = []
        }
        items.map((item) => {
          const group = item.name ? item.name.toUpperCase().charAt(0) : '-'
          const findIndex = this.list.findIndex((item) => item.group === group)

          if (findIndex === -1) {
            this.list.push({
              group,
              items: []
            })
          }
          const findIndexIshave = this.list.findIndex(
            (item) => item.group === group
          )
          if (findIndexIshave !== -1) {
            this.list[findIndexIshave].items.push({ ...item })
          }
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
        if (more) {
          this.loadingMore = false
        }
      }
    },
    changeListingType (par) {
      this.SET_LISTING_TYPE(par === 'box' ? 0 : 1)
      this.listingType = par
    },
    changeSort (par) {
      if (par === 'AZ') {
        this.sort = 'asc'
      }
      if (par === 'ZA') {
        this.sort = 'desc'
      }
      this.sorting = par
      this.page = 1
      this.loading = true
      this.getModelList()
    }
  },
  created () {
    const routeName = this.$route.name
    const name = this.$route.query.seller
    this.supplierId = this.$route.query.id
    this.path = routeName === 'sellerModels' ? 'seller' : 'buyer'
    this.listingType = this.getListingType ? 'list' : 'box'
    if (!name || !this.supplierId) {
      if (routeName === 'sellerModels') {
        return this.$router.push('/seller')
      }
      if (routeName === 'buyerModels') {
        return this.$router.push('/buyer')
      }
    }
    const isSeller = this.getUserOneRole('isSellerUser')
    const isBuyer = this.getUserOneRole('isPurchaserUser')
    if (routeName === 'sellerModels' && isSeller === -1) {
      return this.$router.push('/access-denied')
    }
    if (routeName === 'buyerModels' && isBuyer === -1) {
      return this.$router.push('/access-denied')
    }
    this.getModelList()
    const data = [
      {
        title: name
      },
      {
        title: routeName === 'sellerModels' ? ' / VERKAUF' : ' / Einkauf'
      }
    ]
    this.SET_BREADCRUMB(data)
  }
}
</script>
