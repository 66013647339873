<template>
  <div class="row w-100">
    <div class="col-12">
      <div class="contact-tab-header">
        <h3>Lieferant</h3>
      </div>
    </div>
    <div class="col-12">
      <div class="contact-tab-lieferant-detail">
        <div class="row w-100 justify-content-between">
          <div class="col-6">
            <div class="left">
              <div class="lieferant-logo">

              </div>
              <div class="left-item">
                <span class="id-title">ZR-Nr:</span>
                <span class="id-desc">{{data.supplier.id}}</span>
              </div>
              <div class="left-item">
                <span class="title">{{data.supplier.name}}</span>
              </div>
              <div class="left-item d-none">
                <span class="date">Zr {{data.supplier.validFrom}} bis {{data.supplier.validTill}}</span>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="right">
              <div class="items">
                <div class="item">
                  <span class="item-name">Adresse</span>
                  <span class="item-detail">
                    {{data.supplier.address}}
                  </span>
                  <span class="item-icon">
                    <ph-map-pin />
                  </span>
                </div>
                <div class="item">
                  <span class="item-name">Telefon</span>
                  <span class="item-detail">
                    {{data.supplier.phone}}
                  </span>
                  <span class="item-icon" style="visibility: hidden">
                    <ph-map-pin />
                  </span>
                </div>
                <div class="item">
                  <span class="item-name">Telefax</span>
                  <span class="item-detail">
                    {{data.supplier.fax}}
                  </span>
                  <span class="item-icon" style="visibility: hidden">
                    <ph-map-pin />
                  </span>
                </div>
                <div  class="item">
                  <span class="item-name">E-Mail</span>
                  <a :href="`mailto:${data.supplier.email}?subject=Testing out mailto!`" class="item-detail">
                    {{data.supplier.email}}
                  </a>
                  <span class="item-icon">
                    <ph-envelope-simple />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="contact-list ps-0" v-if="persons.length === 0 && 1 === 0">
        <h3 class="title text-black text-center ms-0">
          Derzeit sind keine Ansprechpartner bekannt.
        </h3>
      </div>
      <loading v-if="loading" class="mt-3" />
        <div class="contact-list" v-if="persons.length !== 0 && !loading">
            <h3 class="title">
                Ansprechpartner
            </h3>
            <div class="items">
                <div v-for="(person, i) in persons" :key="i" class="item">
                    <div class="person">
                        <div class="person-img">
                            <img v-if="person.properties.photo" :src="person.properties.photo" alt="">
                        </div>
                        <div class="person-info">
                            <p class="title">Name</p>
                            <p class="desc">{{`${person.properties.firstname || ''} ${person.properties.lastname}`}}</p>
                        </div>
                        <div  class="person-position">
                            <p class="title">Position</p>
                            <p class="desc">{{person.properties.jobtitle || '-'}}</p>
                        </div>
                        <div class="person-contact">
                            <p>Telefon: {{person.properties.phone}}</p>
                            <p>Mobil: {{person.properties.mobilephone}}</p>
                            <p>Telefax: {{person.properties.fax}}</p>
                            <a :href="`mailto:${person.properties.email}`">E-mail: {{person.properties.email}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import loading from '../common/Loading.vue'
import { PhMapPin, PhEnvelopeSimple } from 'phosphor-vue'
import { mapGetters } from 'vuex'
export default {
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  components: {
    PhMapPin,
    loading,
    PhEnvelopeSimple
  },
  data () {
    return {
      loading: true,
      persons: []
    }
  },
  computed: {
    ...mapGetters(['getUserOneRole'])
  },
  methods: {
    async getContacts (id) {
      try {
        const response = await this.$api.getContactsBySupplier(id)
        this.persons = response.data.data || []
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }
  },
  async created () {
    const isAppAdminUser = this.getUserOneRole('isAppAdminUser')
    if (isAppAdminUser !== -1) {
      const supplierId = this.$route.query.supplierId
      if (this.$route.name === 'buyerCatalogDetail') {
        await this.getContacts(supplierId)
      } else {
        this.loading = false
      }
    } else {
      this.loading = false
    }
  }
}
</script>

<style>
</style>
