<template>
  <div class="card h-100">
    <div class="card-body">
      <span class="person-info-header"> Zuständig </span>
      <div
        v-if="!data.fullName"
        class="d-flex align-items-center justify-content-center h-100"
      >
        <p class="text-center my-auto">Kein Ansprechpartner gepflegt.</p>
      </div>
      <div v-if="data.fullName" class="person-info-img">
        <img :src="data.image" alt="" @error="replaceByDefaultImg" />
      </div>
      <div v-if="data.fullName" class="person-info-name">
        <p>{{ `${data.fullName}` }}</p>
      </div>
      <div v-if="data.fullName" class="person-info-title">
        <p>Einkauf</p>
      </div>
      <!-- <div v-if="data.fullName" class="person-info-contacts">
        <a

          :href="`mailto:${data.email}?subject=Testing out mailto!`"
          class="item"
          :class="{'opacity-05 pointer-none cursor-auto': !data.email}"
        >
          <ph-envelope-simple :size="18" />
        </a>
        <a   :class="{'opacity-05 pointer-none cursor-auto': !data.phone}" href="#" @click.prevent="openPopover = !openPopover" class="item position-relative">
          <ph-phone :size="18" weight="fill" />
          <div v-if="openPopover" class="popover-wrap">
            <div class="kw-popover">{{data.phone}}</div>
          </div>
        </a>
      </div> -->
    </div>
  </div>
</template>

<script>
// import { PhEnvelopeSimple, PhPhone } from 'phosphor-vue'
export default {
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {
      openPopover: false
    }
  },
  methods: {
    replaceByDefaultImg (e) {
      e.target.src = '/imgs/missing_photo.svg'
      e.target.width = 106
    }
  }
  // components: {
  //   PhEnvelopeSimple,
  //   PhPhone
  // }
}
</script>
