<template>
   <div class="page-wrapper-header d-flex justify-content-between">
                <div class="page-wrapper-title">
                  <p class="text-color-65-gray text-fteen mb-0">
                    <span><strong>{{titleItems[0].count}}</strong> {{titleItems[0].title}},</span>
                    <span><strong>{{titleItems[1].count}}</strong> {{titleItems[1].title}}</span>
                  </p>
                </div>
                <div class="d-flex">
                  <div class="page-sorting-items">
                    <span class="me-3 text-color-65-gray"
                      >Sortierung</span
                    >
                    <select
                      @change="changeSort"
                      v-model="sortType"
                      class="form-control sorting-select"
                    >
                      <option selected value="AZ">A-Z</option>
                      <option value="ZA">Z-A</option>
                      <option v-if="isSupplier" value="09">0-9</option>
                      <option v-if="isSupplier" value="90">9-0</option>
                    </select>
                  </div>
                  <div class="d-flex align-items-center ms-4">
                    <span class="text-color-65-gray me-3">Ansicht</span>
                    <div class="page-list-view-change">
                      <div
                        @click="changeListingType('box')"
                        class="item box"
                        :class="{ active: listType === 'box' }"
                      >
                        <v-icon :icon="['fac', 'box']" />
                      </div>
                      <div
                        class="item box"
                        @click="changeListingType('list')"
                        :class="{ active: listType === 'list' }"
                      >
                        <ph-list :size="20" weight="bold" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>

<script>
import { PhList } from 'phosphor-vue'
export default {
  props: {
    isSupplier: {
      type: Boolean,
      default: false
    },
    titleItems: {
      type: Array,
      default: () => []
    },
    sorting: {
      type: String
    },
    listType: {
      type: String
    }
  },
  components: { PhList },
  data () {
    return {
      sortType: this.sorting
    }
  },
  methods: {
    changeListingType (par) {
      this.$emit('changeListingType', par)
    },
    changeSort () {
      this.$emit('sorting', this.sortType)
    }
  }
}
</script>
