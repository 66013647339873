import axios from 'axios'
import { PDFDocument } from 'pdf-lib'

const mergeCoversheet = async (catalogueBuffer, coversheetBuffer) => {
  const pdfsToMerge = [catalogueBuffer, coversheetBuffer]

  const mergedPdf = await PDFDocument.create()
  for (const pdfBytes of pdfsToMerge) {
    const pdf = await PDFDocument.load(pdfBytes)
    const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices())
    copiedPages.forEach((page) => {
      mergedPdf.addPage(page)
    })
  }
  return mergedPdf.save()
}

const downloadPdf = async (file) => {
  const response = await axios.get(file, { responseType: 'arraybuffer' })
  return Buffer.from(response.data, 'utf-8')
}

export default {
  downloadPdf,
  mergeCoversheet
}
