<template>
  <div
    class="sidebar-right pdf"
    :class="{ 'position-fixed top-0 h-100': !openPreview, 'drawer-class slide-l' : drawer, 'slide-r': !drawer }"
    id="sidebar"
  >
    <div v-if="!openPreview">
      <div class="header">
        <div>
          <span class="drawer" @click="ondrawer">
            <ph-x :size="24" v-if="drawer" />
            <ph-list :size="24" v-else/>
          </span>
          <span class="text"> Katalogwand </span>
        </div>
        <div class="icon">
          <span class="me-1">Tutorial</span>
          <ph-info :size="14" weight="bold" />
        </div>
      </div>
      <div class="pdf-border"></div>
      <div class="pdf-preview-content" :class="{'hide-content':!drawer}">
        <h3 class="c-eight">Kataloghistorie</h3>
        <div class="pdf-preview-content-items">
          <div v-for="(item, index) in items" :key="index" class="item">
            <div class="item-header">
              <h3 v-if="$route.name === 'buyerCatalogDetail'" >EK_PREISLISTE{{item.tz ? '_TZ' : ''}}{{item.zusatznr ? `_v${item.zusatznr} ` : ''}}{{item.zusatz ? `_${item.zusatz}` : ''}}</h3>
              <h3 v-if="$route.name === 'sellerCatalogDetail'" >PREISLISTE{{item.tz ? '_TZ' : ''}}{{item.zusatznr ? `_v${item.zusatznr} ` : ''}}{{item.zusatz ? `_${item.zusatz}` : ''}}</h3>
              <div @click="openCatalog(index)"  class="toggle-icon cursor-pointer" :class="{'opened': opened === index}">
                <ph-caret-up v-if="opened === index" :size="14" weight="bold" />
                <ph-caret-down v-else :size="14" weight="bold" />
              </div>
            </div>
            <div class="item-content">
              <div class="item-status">
                <span class="title c-eight">Status</span>
                <span class="status">{{ item.status }}</span>
              </div>
              <div class="item-dates">
                <div class="date">
                  <span class="title c-eight">Erstelldatum</span>
                  <span class="desc">{{
                    getDate(item.SPCZCreateDate)
                  }}</span>
                </div>
                <div class="date">
                  <span class="title">Gültig ab</span>
                  <span class="desc">{{ item.validFrom }}</span>
                </div>
                <div class="date">
                  <span class="title">Gültig bis</span>
                  <span class="desc">{{ item.validTill }}</span>
                </div>
              </div>
            </div>
            <Transition name="slide">
              <div v-if="opened === index" class="item-catalogs">
                <div
                  v-for="(catalog, idx) in item.catalogues"
                  :key="idx"
                  class="catalog"
                >
                  <div class="catalog-header">
                    <div class="icon">
                      <ph-file :size="22" weight="bold" />
                    </div>
                    <h3 class="catalog-title">{{ catalog.stepName }}</h3>
                  </div>
                  <div class="catalog-content d-flex justify-content-between">
                    <button
                      :class="{ 'disable, pointer-none': getLoading(idx) }"
                      @click="downloadPdfbyItem(catalog, idx)"
                      class="btn btn-primary p-2 w-100 me-2"
                    >
                      <ph-arrow-line-down :size="14" />
                      {{ getLoading(idx) ? "Loading.." : "Download" }}
                    </button>
                    <button
                      :class="{
                        'disable, pointer-none': getLoadingPreview(idx),
                      }"
                      @click="openPdfView(catalog, idx)"
                      class="btn btn-secondary p-2 w-100 ms-2"
                    >
                      <ph-arrow-line-up-right :size="14" />
                      {{ getLoadingPreview(idx) ? "Loading.." : "Quick View" }}
                    </button>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
        <div v-if="this.catalouges.length === 0">
          <a href="https://intranet.meinvme.de/php/intranet/ZAblage/INTERLIVING/Preislisten-UVP" class="c-link" target="_blank">VK Listen sind im Interlivingportal zu finden</a>
        </div>
      </div>
    </div>
    <pdf-view
      :title="previewTitle"
      :allItems="
        $route.name === 'sellerCatalogDetail'
          ? orderedCatalogues
          : selectedDocument.catalogues
      "
      :totalPages="totalPages"
      :item="selectPreviewItem"
      @closed="closePreviewPage"
      v-if="openPreview"
    />
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import PdfView from '@/views/PdfView.vue'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import {
  PhArrowLineUpRight,
  PhCaretUp,
  PhCaretDown,
  PhInfo,
  PhFile,
  PhArrowLineDown,
  PhX,
  PhList
} from 'phosphor-vue'
export default {
  props: {
    catalouges: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      opened: 0,
      previewTitle: null,
      selectPreviewItem: '',
      supplierId: null,
      modelId: null,
      totalPages: 0,
      openPreview: false,
      currentIndex: 0,
      selectedDocument: null,
      slides: [],
      parsedDocument: {},
      items: [],
      loadingBtns: ['1'],
      loadingPreviewBtns: ['1'],
      drawer: false
    }
  },
  components: {
    PhInfo,
    PhCaretDown,
    PhCaretUp,
    PdfView,
    PhArrowLineUpRight,
    PhArrowLineDown,
    PhFile,
    PhX,
    PhList
  },
  computed: {
    ...mapGetters(['getUserRole', 'getAccessToken']),
    orderedCatalogues: function () {
      return _.orderBy(this.selectedDocument.catalogues, 'sortOrder')
    }
  },
  methods: {
    openCatalog (index) {
      if (this.opened === index) {
        this.opened = -1
        return
      }
      this.opened = index
    },
    getDate (date) {
      return DateTime.fromJSDate(new Date(date)).toFormat('dd.MM.yyyy')
    },
    async openPdfView (item, index) {
      this.loadingPreviewBtns.push(index)
      const response = await this.$api.viewDocument(item)
      this.deleteLoadingPreview(index)
      window.open(response.data.file, '_blank')
    },
    closePreviewPage () {
      this.openPreview = false
      this.selectPreviewItem = null
      localStorage.removeItem('pdfjs.history')
      const x = this.$parent.$refs.container
      x.classList.add('ps')
    },
    deleteLoading (id) {
      for (var i = 0; i < this.loadingBtns.length; i++) {
        if (this.loadingBtns[i] === id) {
          this.loadingBtns.splice(i, 1)
        }
      }
    },
    deleteLoadingPreview (id) {
      for (var i = 0; i < this.loadingPreviewBtns.length; i++) {
        if (this.loadingPreviewBtns[i] === id) {
          this.loadingPreviewBtns.splice(i, 1)
        }
      }
    },
    getLoading (id) {
      const findIndex = this.loadingBtns.findIndex((item) => item === id)
      if (findIndex !== -1) {
        return true
      }
      return false
    },
    getLoadingPreview (id) {
      const findIndex = this.loadingPreviewBtns.findIndex(
        (item) => item === id
      )
      if (findIndex !== -1) {
        return true
      }
      return false
    },
    parseLabel (par) {
      const parsed = par.split('+')
      return `${parsed[0]} - ${parsed[1]} - ${parsed[3] || ''} (${parsed[2]})`
    },
    getStepVal (par) {
      const name = par.split('+').slice(-1).pop()
      return name
    },
    downloadPdf () {
      this.$emit('downloadPdf', this.selectedDocument)
    },
    downloadPdfbyItem (item, index) {
      const par = {
        index,
        ...item
      }
      this.loadingBtns.push(index)
      this.$emit('downloadPdf', par)
    },
    resetPointer () {
      this.$refs.documentSelect.pointer = 0
      if (this.$refs.documentSelect.$refs.list) {
        this.$refs.documentSelect.$refs.list.scrollLeft = 0
        this.$refs.documentSelect.$refs.list.scrollTop = 0
      }
    },
    selectDocument (selectedOption) {
      this.parseDocument(selectedOption.label)
    },
    changeIndex (par) {
      this.currentIndex = par
    },
    prev () {
      this.$refs.carousel.goPrev()
    },
    next () {
      this.$refs.carousel.goNext()
    },
    parseDocument (par) {
      const [validFrom, validTill, status] = par.split('+')
      this.parsedDocument = {
        status,
        gultig: validTill,
        validFrom: validFrom
      }
    },
    ondrawer () {
      this.drawer = !this.drawer
    }
  },
  created () {
    this.supplierId = this.$route.query.supplierId
    this.modelId = this.$route.params.id
    if (this.catalouges.length > 0) {
      this.items = this.catalouges
      this.selectedDocument = this.items[0]
      this.parseDocument(this.selectedDocument.label)
    }
  }
}
</script>

<style>
.c-eight {
  color: #c8c8c8 !important;
}

.c-link{
  color: #ffa541;
  text-decoration: none;
}

.c-link:hover {
  color: #ffa541;
  text-decoration: underline;
}

.drawer{
  color:white;
  margin-left: -45px;
  visibility: hidden;
}

.slide-enter-active {
  transition: all 0.4s;
}

.slide-enter {
  transform: translateY(30px);
}

@media only screen and (max-width: 1024px) {
  .drawer{
    visibility:visible;
    margin-left: unset;
    cursor: pointer;
    margin-right: 1em;
  }
  .drawer-class {
    right: -10px;
    z-index: 1000;
  }
  .hide-content{
    display: none;
  }
}
</style>
