<template>
  <div class="card">
      <div class="card-body">
          <div class="model-info-header d-flex w-100 align-items-start">
              <div class="model-info-image me-4">
                <img  :src="data.modelImage" @error="replaceByDefaultImg" alt="Model name">
              </div>
              <div class="model-supplier model-info-header-right flex-grow-1 d-flex flex-column justify-content-between">
                  <div class="model-number">
                    <span class="desc text-xs text-color-primary ms-1 font-500">{{ getVAN(data.supplierId, data.modelId) }}</span>
                    <span class="desc text-xs text-color-primary ms-1 font-500">{{ `(${pad(data.supplierId, 6)}-${pad(data.modelId, 4)})` }}</span>
                  </div>
                  <div class="item" @click="$router.push({ path: `/${$route.name === 'sellerCatalogDetail' ? 'seller' : 'buyer'}/models?seller=${data.supplierName}&id=${data.supplierId}` })">
                    <div class="item-left">
                        <img src="/imgs/dummy_logo.png" alt="">
                    </div>
                    <div class="item-right">
                        <span class="title text-sm">{{ data.supplierName }}</span>
                    </div>
                  </div>
                  <span class="model-title text-sm font-500">Modell: {{data.modelName}}</span>
              </div>
              <div class="model-info-footer px-2">
                <div class="item-right align-self-center">
                  <span class="desc">Status:</span> <span class="title text-color-success">{{ data.status }}</span>
                </div>
                <div class="item">
                    <div class="item-left">
                        <img src="/imgs/dummy_model_icon.png" alt="">
                    </div>
                    <div class="item-right">
                        <span class="desc">Warengruppe</span>
                        <span class="title">{{data.goodsGroup ? data.goodsGroup.split(' ')[0] : ''}}</span>
                    </div>
                </div>
                <div class="text-sm text-color-gray align-self-end" v-if="showTzText(modelInfo)">
                  <span>{{getTzText(modelInfo)}}</span>
                </div>
            </div>
            <div class="btn-currency" @click="changeRoute" v-if="isEinkauf">
              <ph-currency-eur weight="bold"  :size="24" color="#ee7f00"/>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
import {
  PhCurrencyEur
} from 'phosphor-vue'
export default {
  components: { PhCurrencyEur },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {}
    },
    modelInfo: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
    isEinkauf () {
      return this.$route.name === 'buyerCatalogDetail'
    }
  },
  methods: {
    replaceByDefaultImg (e) {
      e.target.src = '/imgs/missing_photo.svg'
    },
    changeRoute () {
      this.$router
        .push({
          path: `/seller/model/${this.$route.params.id}`,
          query: { supplierId: this.$route.query.supplierId }
        })
    },
    showTzText (item) {
      return item.tz || this.hasTeuerungszuschlagInfo(item)
    },
    hasTeuerungszuschlagInfo (item) {
      return item.TeuerungszuschlagInProzent ||
              item.TeuerungszuschlagInformation ||
              item.TeuerungszuschlagGültigVon ||
              item.TeuerungszuschlagGültigBis
    },
    getTzText (item) {
      if (item.tz === true) {
        return 'PL - inkl. TZ kalkuliert'
      }
      if (item.tz === false && this.hasTeuerungszuschlagInfo(item)) {
        return 'PL, ohne TZ kalkuliert bitte TZ-Informationen beachten'
      }
      if (!item.tz && this.hasTeuerungszuschlagInfo(item)) {
        return 'TZ-Infos im Modell beachten'
      }
      return ''
    },
    getVAN (supp, mod) {
      return `${this.pad(supp, 6)}${this.pad(mod, 4)}`
    },
    pad (num, size) {
      num = num.toString()
      while (num.length < size) num = '0' + num
      return num
    }
  },
  name: 'ModelInfo'
}
</script>
<style scoped lang="scss">
.model-supplier {
  height: 115px;
  .item {
    display: flex;
    cursor: pointer;
    &-left {
      margin-right: 5px;
      img {
        width: 32px;
        height: 32px;
      }
    }
    &-right {
      align-self: center;
      .title {
        font-weight: 500;
        color: #555555;
      }
    }
  }

  .model-title {
    color: #555555;
  }

  .font-500 {
    font-weight: 500;
  }
}

.model-number {
  display: flex;
  flex-direction: column;
}

.btn-currency{
  cursor: pointer;
  border-radius: 6px;
  background-color: #f0f0f0;
  padding: .5rem;
  width: fit-content;
  margin: 0 10px;
}
.btn-currency:hover{
  background-color: #ebebeb;
}
</style>
