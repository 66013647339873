import { render, staticRenderFns } from "./ModelInfo.vue?vue&type=template&id=89d92366&scoped=true&"
import script from "./ModelInfo.vue?vue&type=script&lang=js&"
export * from "./ModelInfo.vue?vue&type=script&lang=js&"
import style0 from "./ModelInfo.vue?vue&type=style&index=0&id=89d92366&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89d92366",
  null
  
)

export default component.exports