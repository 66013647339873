<template>
  <div class="row w-100">
    <div class="d-flex" v-if="data">
        <div class="d-flex flex-column me-5">
          <span class="font-bold">Zur Marketingbox:</span>
          <span class="font-bold my-2">Werbekennung:</span>
          <span class="font-bold">Werbekennung Historie:</span>
        </div>
        <div class="d-flex flex-column">
          <a href="https://cms.marketingbox.de/login" target="_blank">https://cms.marketingbox.de/login</a>
          <span class="my-2">{{data.Aktionsmerker}}</span>
          <span>{{data.Werbekennung_Historie}}</span>
        </div>
    </div>
    <h4 class="text-center" v-else>Derzeit sind keine Aktionen bekannt</h4>
  </div>
</template>

<script>
export default {
  props: ['data']
}
</script>
