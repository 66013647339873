<template>
  <div class="content">
    <div
      v-if="loading"
      class="d-flex justify-content-center align-items-center h-100 w-100"
    >
      <loading />
    </div>
    <div v-if="!loading" class="d-flex w-100 position-relative">
      <perfect-scrollbar class="d-flex w-100" ref="ps">
      <div class="content-section w-100 px-4 ">
        <div class="row">
          <div :class="{'model-info-cont' : showCata}">
            <page-title class="position-sticky top-0 " style="z-index: 1000;width: 100%;" />
            <div class="row align-items-stretch mt-3">
              <div class="col-7">
                <model-info :data="detail.summary" :modelInfo="detail.modelInfo"/>
              </div>
              <div class="col-5">
                <person-info v-if="detail.responsiblePerson" :data="detail.responsiblePerson" />
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12">
                <div class="tabs">
                  <div class="tab-header">
                    <ul>
                      <template  v-for="item in items">
                        <li v-if="item.visible" :key="item.id">
                        <a
                          @click="changeTab(item.id)"
                          :class="{ active: item.id === selectedTab, 'pointer-none': item.disable}"
                          href="#"
                          >{{ item.name }}</a
                        >
                      </li>
                      </template>

                    </ul>
                  </div>
                  <div class="tab-content">
                    <info-tab :data="detail.modelInfo" v-if="selectedTab === 0" />
                    <contact-tab :data="detail.contacts" v-if="selectedTab === 5" />
                    <aktionen-tab :data="detail.modelInfo" v-if="selectedTab === 4" />
                    <tz-tab :data="detail.modelInfo"   v-if="selectedTab === 3" />
                    <ausfuhrung :data="detail.additions" v-if="selectedTab === 2" />
                    <kalkulations :modelId="modelId" :supplierId="supplierId" v-if="selectedTab === 6" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="{'pdf-cont' : showCata}">
            <pdf-preview-side
              ref="pdfSide"
              @downloadPdf="downloadCatalog"
              :catalouges="documents"
              v-if="!loading && isVisibleConditionRole && showCata"
            />
          </div>
        </div>
      </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { DateTime } from 'luxon'
import pdfHelper from '../libs/pdf'
import fileDownload from 'js-file-download'
import TzTab from '@/components/tabs/Tz.vue'
import { mapMutations, mapGetters } from 'vuex'
import infoTab from '@/components/tabs/ModelInfo.vue'
import ContactTab from '@/components/tabs/Contact.vue'
import Loading from '../components/common/Loading.vue'
import aktionenTab from '@/components/tabs/Aktionen.vue'
import PageTitle from '@/components/common/PageTitle.vue'
import ModelInfo from '@/components/detail/ModelInfo.vue'
import Ausfuhrung from '@/components/tabs/Ausfuhrung.vue'
import PersonInfo from '@/components/detail/PersonInfo.vue'
import kalkulations from '@/components/tabs/kalkulations.vue'
import PdfPreviewSide from '../components/common/PdfPreviewSide.vue'

export default {
  components: {
    PageTitle,
    ModelInfo,
    PersonInfo,
    Ausfuhrung,
    infoTab,
    aktionenTab,
    kalkulations,
    ContactTab,
    TzTab,
    Loading,
    PdfPreviewSide
  },
  name: 'CatalogDetail',
  methods: {
    ...mapMutations(['SET_BREADCRUMB', 'SET_VERKAUF_TAB', 'SET_EINKAUF_TAB']),
    changeTab (par) {
      this.selectedTab = par
      this.$route.name === 'buyerCatalogDetail'
        ? this.SET_EINKAUF_TAB(par) : this.SET_VERKAUF_TAB(par)
    },
    async getModelDetail () {
      try {
        const response = await this.$api.getModelDetail(
          this.supplierId,
          this.modelId
        )
        this.detail = response.data.data
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
        this.$emit('loading', false)
      }
    },
    async getModelCatalogs () {
      try {
        let type = null
        if (this.$route.name === 'sellerCatalogDetail') {
          type = 'seller'
        }
        if (this.$route.name === 'buyerCatalogDetail') {
          type = 'purchaser'
        }
        const data = {
          modelId: this.modelId,
          supplierId: this.supplierId
        }
        const response = await this.$api.getModelCatalog(data, type)
        const dataCat = response.data.data
        const temp = []
        for (const item in dataCat) {
          let key = dataCat[item].validFrom + '+' + dataCat[item].validTill + '+' + dataCat[item].status + (dataCat[item]?.tz ? '+TZ' : '+')
          if (dataCat[item].zusatznr) {
            key = key + '+' + dataCat[item].zusatznr
          }
          const findIndex = temp.findIndex(tempItem => tempItem.label === key)
          if (findIndex === -1) {
            temp.push({
              label: key,
              ...dataCat[item],
              catalogues: []
            })
          }
        }
        temp.map(catalogue => {
          const parseCatalogue = catalogue.label.split('+')
          for (const item in dataCat) {
            if (dataCat[item].validFrom === parseCatalogue[0] && dataCat[item].validTill === parseCatalogue[1] && dataCat[item].status === parseCatalogue[2] && dataCat[item].tz === (!!parseCatalogue[3]) && dataCat[item].zusatznr === parseCatalogue[4]) {
              catalogue.catalogues.push({
                ...dataCat[item],
                sortOrder: parseInt(dataCat[item].sortOrder.split('field')[1])

              })
            }
          }
        })
        temp.sort((a, b) => {
          return DateTime.fromFormat(b.validFrom, 'dd.MM.yyyy').toJSDate() - DateTime.fromFormat(a.validFrom, 'dd.MM.yyyy').toJSDate()
        })
        this.documents = temp
      } catch (e) {
        console.error(e)
      }
    },
    parseCatalogVal (par) {
      const [validFrom, validTill, stepValue, status, TZ] = par.split('+')
      const TZVal = TZ === '_TZ'
      return this.catalogues.filter(catalogue => {
        return catalogue.validFrom === validFrom && catalogue.validTill === validTill && catalogue.status === status && catalogue.stepValue === stepValue && catalogue.tz === TZVal
      })
    },
    async downloadCatalog (par) {
      try {
        let data = {
          tz: par.tz,
          step: par.stepValue,
          status: par.status,
          validFrom: par.validFrom,
          validTill: par.validTill
        }
        if (par.zusatznr) data = { ...data, zusatznr: par.zusatznr }
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/catalogue/supplier/${this.supplierId}/model/${this.modelId}/download`,
          data,
          { headers: { Authorization: 'Bearer ' + this.getAccessToken } }
        )

        const coversheetBuffer = Buffer.from(response.data.coversheet)
        const catalogueBuffer = await pdfHelper.downloadPdf(response.data.file)
        const buffer = await pdfHelper.mergeCoversheet(coversheetBuffer, catalogueBuffer)

        let filename = `${this.detail.summary.modelName}_${par.tz ? 'TZ' : ''}_${par.validFrom}_${par.validTill}_${par.status}_${par.supplierId}_${par.modelId}_${par.stepName}`
        if (par.zusatznr) {
          filename += `_v${par.zusatznr}`
        }
        if (par.zusatz) {
          filename += `_${par.zusatz}`
        }
        fileDownload(buffer, `${filename}.pdf`)
      } catch (e) {
        console.error(e)
      } finally {
        this.$refs.pdfSide.deleteLoading(par.index)
      }
    }
  },
  data () {
    return {
      loading: true,
      detail: {},
      supplierId: null,
      modelId: null,
      selectedTab: 0,
      catalogues: [],
      documents: [],
      items: [
        {
          visible: true,
          id: 0,
          name: 'Modellinfo'
        },
        {
          visible: true,
          disable: false,
          id: 2,
          name: 'Ausführung'
        },
        {
          visible: true,
          disable: false,
          id: 3,
          name: 'Teuerungszuschläge'
        },
        {
          visible: true,
          disable: false,
          id: 4,
          name: 'Aktionen'
        },
        {
          visible: true,
          id: 5,
          name: 'Kontakt'
        },
        {
          visible: true,
          disable: false,
          id: 6,
          name: 'Kalkulationen'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['getUserRole', 'getAccessToken', 'getUserOneRole', 'getVerkaufTab', 'getEinkaufTab']),
    isVisibleConditionRole () {
      const roles = this.getUserRole
      if (this.$route.name === 'buyerCatalogDetail') {
        const role = roles.findIndex(item => item === 'isMerchantAdminUser' || item === 'isPurchaserUser')
        if (role !== -1) {
          return true
        }
      }
      return true
    },
    showCata () {
      return this.documents.length > 0 || this.supplierId[0] === '9'
    }
  },
  created () {
    this.$emit('loading', true)
    const isBuyer = this.getUserOneRole('isPurchaserUser')
    const isSeller = this.getUserOneRole('isSellerUser')
    if (this.$route.name === 'buyerCatalogDetail' && isBuyer === -1) {
      return this.$router.push('/access-denied')
    }
    if (this.$route.name === 'sellerCatalogDetail' && isSeller === -1) {
      return this.$router.push('/access-denied')
    }
    this.selectedTab = this.$route.name === 'sellerCatalogDetail'
      ? this.getVerkaufTab : this.getEinkaufTab
    this.supplierId = this.$route.query.supplierId
    this.modelId = this.$route.params.id
    this.getModelCatalogs()
    this.getModelDetail()
    if (this.$route.name !== 'buyerCatalogDetail') {
      for (const item of this.items) {
        if (item.name === 'Teuerungszuschläge' || item.name === 'Kalkulationen' || item.name === 'Aktionen') {
          item.visible = false
        }
      }
    }
    const data = [
      {
        title: 'Modell'
      },
      {
        title: this.$route.name !== 'sellerCatalogDetail' ? ' / EINKAUF' : ' / VERKAUF'
      }
    ]
    this.SET_BREADCRUMB(data)
  }
}
</script>

<style>
.model-info-cont {
  flex: 0 0 auto;
  width: calc(100% - 300px);
}
.pdf-cont {
  flex: 0 0 auto;
  width: 300px;
}
@media only screen and (max-width: 1024px) {
  .model-info-cont {
    width: calc(100% - 50px);
  }
  .pdf-cont {
    width: 50px;
  }
}
</style>
