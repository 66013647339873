<template>
  <div class="row justify-content-between w-100">
      <div v-if="hidden" class="col-12">
          <h3 class="text-center">Es liegen noch keine Informationen vor.</h3>
      </div>
    <div v-if="data.TeuerungszuschlagInformation"  class="col-7">
      <div class="w-85">
        <p class="text-uppercase">
          <strong>Teuerungszuschlag Information</strong>
        </p>
        <div class="item d-flex justify-content-between mb-1">
          <span class="ms-1 text-left d-block w-75">
            {{data.TeuerungszuschlagInformation}}
          </span>
        </div>
      </div>
    </div>
    <div v-if="!hidden" class="col-4 buyer-condition">
      <p class="text-sm" style="border-bottom: 1px solid #c8c8c8; padding-bottom: 10px;">
        <span>Teuerungszuschlag in Prozent:</span
        ><strong class="ms-1">{{data.TeuerungszuschlagInProzent}}</strong>
      </p>
      <p class="text-sm">
        <span>Teuerungszuschlag gültig von:</span
        ><strong class="ms-1">{{data.TeuerungszuschlagGültigVon}}</strong>
      </p>
      <p class="text-sm">
        <span>Teuerungszuschlag gültig bis:</span
        ><strong class="ms-1">{{data.TeuerungszuschlagGültigBis}}</strong>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['getUserRole'])
  },
  data () {
    return {
      hidden: true,
      routeName: null
    }
  },
  created () {
    this.routeName = this.$route.name
    if (this.data.TeuerungszuschlagGültigBis || this.data.TeuerungszuschlagGültigVon || this.data.TeuerungszuschlagInProzent) {
      this.hidden = false
    }
  }
}
</script>

<style></style>
