<template>
  <div class="row w-100">
    <div class="col-12">
        <div class="execution-header d-flex justify-content-between align-items-center">
            <h2 class="me-5">Ausführung</h2>
            <div class="form-group w-50">
                <select @change="changeVariant" name="" id="" class="form-control w-100">
                    <option v-for="(item, index) in variants" :key="index" :value="index">{{pad(item.AusführungsNummerAlt,2)}}_{{item.HerstellerAusprägungsName}} </option>
                </select>
            </div>
        </div>
        <div v-for="(item, index) in parts" :key="index" class="w-100" >
            <div  class="execution-variant d-flex justify-content-between">
                <div class="left me-5 d-flex align-items-center">
                    <div class="execution-item">
                        <p class="title p-0">
                            Nr
                        </p>
                        <p class="desc p-0 number transparent">
                            {{pad(item.AusführungsNummerAlt,2)}}
                        </p>
                    </div>
                    <div class="execution-item ms-4">
                        <p class="title p-0">
                            Bezeichnung
                        </p>
                        <p class="desc p-0">
                            {{item.parts[0].HerstellerAusprägungsName}}
                        </p>
                        <p class="desc p-0 orange">
                            VAN: {{ getVAN([{ val: item.parts[0].supplierId, count: 6} , { val: item.parts[0].modelId, count: 4}, { val: item.AusführungsNummerAlt, count: 3}]) }}
                        </p>
                        <p class="desc p-0 orange">
                          {{ `(${pad(item.parts[0].supplierId, 6)}-${pad(item.parts[0].modelId, 4)}-${pad(item.AusführungsNummerAlt, 3)})` }}
                        </p>
                    </div>
                </div>
                <div class="right w-50 d-flex justify-content-between">
                    <div class="execution-item w-50">
                        <p class="title p-0">
                            Beschreibung
                        </p>
                        <p class="desc p-0">
                            {{item.parts[0].AusprägungsText}}
                        </p>
                    </div>
                    <div class="execution-item me-0">
                        <p class="title p-0 text-right">
                            Status
                        </p>
                        <p class="desc p-0 text-right">
                            {{item.parts[0].status}}
                        </p>
                    </div>
                </div>
            </div>
            <div class="part-items">
                <div v-for="(part, index) in item.parts" :key="index" class="part-item d-flex justify-content-between">
                <div class="left d-flex w-50">
                    <div class="execution-item">
                        <p class="title p-0">
                            Teil
                        </p>
                        <p class="desc p-0 number ">
                            {{pad(part.TeilNummerAlt,3)}}
                        </p>
                    </div>
                    <div class="w-50 ">
                        <div class="execution-item ">
                            <p class="title p-0">
                                Bezeichnungen
                            </p>
                            <p class="desc p-0  ">
                                {{part.HerstellerArtikelName}}
                            </p>
                        </div>
                        <div v-if="$route.name !== 'sellerCatalogDetail'" class="execution-item d-flex ">
                            <p class="title p-0 me-2">
                                Status:
                            </p>
                            <p class="desc p-0 mt-0  ">
                                {{part.status}}
                            </p>
                        </div>
                        <div class="execution-item d-flex " v-if="part.HerstellerArtikelNummer">
                            <p class="desc p-0 me-2">
                               {{part.HerstellerArtikelNummer}}
                            </p>
                        </div>
                        <div class="execution-item d-flex " v-if="part.Etikett1_30">
                            <p class="desc p-0 me-2">
                               {{part.Etikett1_30}}
                            </p>
                        </div>
                        <div class="execution-item d-flex ">
                            <p class="desc p-0 me-2">
                               <span style="color: #b1aeae;">EAN: </span>{{part.EAN}}
                            </p>
                        </div>
                        <div class="execution-item d-flex flex-column">
                            <p class="desc p-0 me-2 orange">
                               VAN: {{ getVAN([{ val: part.supplierId, count: 6} , { val: part.modelId, count: 4}, { val: part.AusführungsNummerAlt, count: 3}, { val: part.TeilNummerAlt, count: 3}]) }}
                            </p>
                            <p class="desc p-0 me-2 orange">
                              {{ `(${pad(part.supplierId, 6)}-${pad(part.modelId, 4)}-${pad(part.AusführungsNummerAlt, 3)}-${pad(part.TeilNummerAlt, 3)})` }}
                            </p>
                        </div>
                        <div class="execution-item d-flex ">
                            <p class="desc p-0 me-2">
                               Verpackungseinheit: {{part.VPEMenge}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="right w-50 d-flex justify-content-between ">
                    <div class="execution-item">
                        <p class="title p-0">
                            Beschreibung
                        </p>
                        <p class="desc p-0 ">
                            {{part.VKText}}
                        </p>
                        <p class="desc p-0 ">
                            {{part.Shop_Langtext}}
                        </p>
                    </div>
                    <div v-if="$route.name !== 'sellerCatalogDetail'" class="d-flex flex-column align-items-flex-end">
                        <div class="execution-item me-0">
                            <p class="title p-0 text-right">
                                Preis
                            </p>
                        </div>
                        <div class="execution-item me-0" v-if="part.EKNettoNetto" >
                            <p class="title price p-0">
                                N/N
                            </p>
                            <p class="desc price p-0">{{part.EKNettoNetto}} €</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.EKFakturierpreis">
                            <p class="title price p-0">Faktura</p>
                            <p class="desc price p-0">{{part.EKFakturierpreis}} €</p>
                        </div>
                        <div class="execution-item me-0"  v-if="part.EKBrutto">
                          <p class="title price p-0">BRL</p>
                          <p class="desc price p-0">{{part.EKBrutto}} €</p>
                        </div>
                        <div  class="execution-item me-0"  v-if="part.UVPHerst">
                          <p class="title price p-0">UVP Hersteller</p>
                          <p class="desc price p-0">{{part.UVPHerst}} €</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.EKBrutto">
                          <p class="title price p-0">EKBrutto</p>
                          <p class="desc price p-0 ">{{part.EKBrutto}} €</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.EKFakturierpreis">
                          <p class="title price p-0">EKFaktura</p>
                          <p class="desc price p-0">{{part.EKFakturierpreis}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.EKNettoVSK">
                          <p class="title price p-0">EK-NettoVSK</p>
                          <p class="desc price p-0 ">{{part.EKNettoVSK}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.EKNettoNetto">
                          <p class="title price p-0">EK-NettoNetto</p>
                          <p class="desc price p-0 ">{{part.EKNettoNetto}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.EKKernsortimentNettoNetto">
                          <p class="title price p-0">EK-KS-NN</p>
                          <p class="desc price p-0">{{part.EKKernsortimentNettoNetto}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.EKKernsortiment">
                          <p class="title price p-0">EK-KS-NVSK</p>
                          <p class="desc price p-0 ">{{part.EKKernsortiment }}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.EKKernsortimentRabatt">
                          <p class="title price p-0">EK-KS-Rabatt</p>
                          <p class="desc price p-0 ">{{part.EKKernsortimentRabatt }}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.EKZuteilungNettoNetto">
                          <p class="title price p-0">EK-ZT-NN</p>
                          <p class="desc price p-0 ">{{part.EKZuteilungNettoNetto}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.EKZuteilung">
                          <p class="title price p-0">EK-ZT-NVSK</p>
                          <p class="desc price p-0">{{part.EKZuteilung}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.EKZuteilungRabatt">
                          <p class="title price p-0">EK-ZT-Rabatt</p>
                          <p class="desc price p-0 ">{{part.EKZuteilungRabatt}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.EKMengeNettoVSK">
                          <p class="title price p-0">EK-Menge-NVSK</p>
                          <p class="desc price p-0 ">{{part.EKMengeNettoVSK}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.EKMengeNettoNetto">
                          <p class="title price p-0">EK-Menge-NN</p>
                          <p class="desc price p-0 ">{{part.EKMengeNettoNetto}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.EKMengeRabatt">
                          <p class="title price p-0">EK-Menge-Rabatt</p>
                          <p class="desc price p-0 ">{{part.EKMengeRabatt }}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.EKMengeNachdispoNettoVSK">
                          <p class="title price p-0">EK-Menge-Nachdispo-NVSK</p>
                          <p class="desc price p-0 ">{{part.EKMengeNachdispoNettoVSK }}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.EKMengeNachdispoNettoNetto">
                          <p class="title price p-0">EK-Menge-Nachdispo-NN</p>
                          <p class="desc price p-0 ">{{part.EKMengeNachdispoNettoNetto}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.EKMengeNachdispoRabatt">
                          <p class="title price p-0">EK-Menge-Nachdispo-Rabatt</p>
                          <p class="desc price p-0">{{part.EKMengeNachdispoRabatt}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.Cont20CIFDollarNN">
                          <p class="title price p-0">Cont 20 CIF- $ -NN</p>
                          <p class="desc price p-0 ">{{part.Cont20CIFDollarNN}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.Cont20CIFDollarNNGültigBis">
                          <p class="title price p-0">Cont 20 CIF- $ -NN Gültig Bis</p>
                          <p class="desc price p-0 ">{{part.Cont20CIFDollarNNGültigBis}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.Cont40CIFDollarNN">
                          <p class="title price p-0">Cont 40 CIF- $ -NN</p>
                          <p class="desc price p-0 ">{{part.Cont40CIFDollarNN }}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.Cont40CIFDollarNNGültigBis">
                          <p class="title price p-0">Cont 40 CIF- $ -NN Gültig Bis</p>
                          <p class="desc price p-0 ">{{part.Cont40CIFDollarNNGültigBis}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.Cont20DDPEuroNN">
                          <p class="title price p-0">Cont20DDPEuroNN</p>
                          <p class="desc price p-0 ">{{part.Cont20DDPEuroNN}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.Cont20DDPEuroNNGültigBis">
                          <p class="title price p-0">Cont 20 DDP- € -NN</p>
                          <p class="desc price p-0 ">{{part.Cont20DDPEuroNNGültigBis}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.Cont40DDPEuroNN">
                          <p class="title price p-0">Cont 40 DDP- € -NN</p>
                          <p class="desc price p-0 ">{{part.Cont40DDPEuroNN }}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.Cont40DDPEuroNNGültigBis">
                          <p class="title price p-0">Cont 40 DDP- € -NN Gültig Bis</p>
                          <p class="desc price p-0 ">{{part.Cont40DDPEuroNNGültigBis}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.Cont20FOBDollarNN">
                          <p class="title price p-0">Cont 20 FOB- $ -NN</p>
                          <p class="desc price p-0">{{part.Cont20FOBDollarNN}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.Cont20FOBDollarNNGültigBis">
                          <p class="title price p-0">Cont 20 FOB- $ -NN Gültig Bis</p>
                          <p class="desc price p-0 ">{{part.Cont20FOBDollarNNGültigBis}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.Cont20FOBEuroNN">
                          <p class="title price p-0">Cont 20 FOB- € -NN</p>
                          <p class="desc price p-0 ">{{part.Cont20FOBEuroNN }}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.Cont20FOBEuroNNGültigBis">
                          <p class="title price p-0">Cont 20 FOB- € -NN Gültig Bis</p>
                          <p class="desc price p-0 ">{{part.Cont20FOBEuroNNGültigBis}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.Cont40FOBDollarNN">
                          <p class="title price p-0">Cont 40 FOB- $ -NN</p>
                          <p class="desc price p-0">{{part.Cont40FOBDollarNN}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.Cont40FOBDollarNNGültigBis">
                          <p class="title price p-0">Cont 40 FOB- $ -NN Gültig Bis</p>
                          <p class="desc price p-0 ">{{part.Cont40FOBDollarNNGültigBis}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.Cont40FOBEuroNN">
                          <p class="title price p-0">Cont 40 FOB- € -NN</p>
                          <p class="desc price p-0 ">{{part.Cont40FOBEuroNN}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.Cont40FOBEuroNNGültigBis">
                          <p class="title price p-0">Cont 40 FOB- € -NN Gültig Bis</p>
                          <p class="desc price p-0 ">{{part.Cont40FOBEuroNNGültigBis }}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.FBZEuroNN">
                          <p class="title price p-0">FBZ-NN</p>
                          <p class="desc price p-0 ">{{part.FBZEuroNN}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.FBZEuroNNGültigBis">
                          <p class="title price p-0">FBZ-NN Gültig Bis</p>
                          <p class="desc price p-0">{{part.FBZEuroNNGültigBis}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.SaisonEuroNN">
                          <p class="title price p-0">Saisonpreis NN</p>
                          <p class="desc price p-0 ">{{part.SaisonEuroNN}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.SaisonEuroNNGültigBis">
                          <p class="title price p-0">Saisonpreis NN Gültig Bis</p>
                          <p class="desc price p-0 ">{{part.SaisonEuroNNGültigBis}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.UVPVME">
                          <p class="title price p-0">Aktionsvorschlag VME</p>
                          <p class="desc price p-0 ">{{part.UVPVME }}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.UVPHerst">
                          <p class="title price p-0">Aktionsvorschlag Hersteller</p>
                          <p class="desc price p-0 ">{{part.UVPHerst}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.Aktionspreis">
                          <p class="title price p-0">Sonderaktionsvorschlag (Werbung / Print)</p>
                          <p class="desc price p-0">{{part.Aktionspreis}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.InterlivingUVP">
                          <p class="title price p-0">Aktionsvorschlag Interliving</p>
                          <p class="desc price p-0 ">{{part.InterlivingUVP}}</p>
                        </div>
                        <div class="execution-item me-0" v-if="part.AktionsvorschlagOnline">
                          <p class="title price p-0">Aktionsvorschlag / Online</p>
                          <p class="desc price p-0 ">{{part.AktionsvorschlagOnline}}</p>
                        </div>
                    </div>
                    <div v-if="$route.name === 'sellerCatalogDetail'" class="d-flex align-items-flex-end">
                       <div class="execution-item me-0">
                        <p class="title p-0 text-right">
                            Status
                        </p>
                        <p class="desc p-0 ">
                            {{part.status}}
                        </p>
                    </div>
                    </div>
                </div>
            </div>
            </div>
        </div>

    </div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  props: ['data'],
  data () {
    return {
      isShow: false,
      variants: [],
      parts: []
    }
  },
  methods: {
    changeVariant (e) {
      const selectItem = this.variants[e.target.value]
      this.parts = _.chain(selectItem.items).groupBy('AusführungsNummerAlt').map((value, key) => ({ AusführungsNummerAlt: key, parts: value })).value()
    },
    getVAN (arr) {
      let van = ''
      arr.forEach(ele => {
        van += `${this.pad(ele.val, ele.count)}`
      })
      return van
    },
    pad (num, size) {
      num = num.toString()
      while (num.length < size) num = '0' + num
      return num
    }
  },
  created () {
    const items = this.data
    const variants = _.chain(items).groupBy('AusführungsNummerAlt').map((value, key) => ({ AusführungsNummerAlt: key, HerstellerAusprägungsName: value[0].HerstellerAusprägungsName, items: value })).value()
    this.variants = variants
    const selectItem = variants[0]
    this.parts = _.chain(selectItem.items).groupBy('AusführungsNummerAlt').map((value, key) => ({ AusführungsNummerAlt: key, parts: value })).value()
  }

}
</script>
